h2 {
  color: rgb(5, 56, 92);
  font-family: Calibri, "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
  margin-bottom: 4px;
}

p {
  font-family: Calibri, "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
  font-size: 18px;
}

h3 {
  color: rgb(3, 125, 158);
  font-family: Calibri, "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
  margin-bottom: 4px;
}
h4 {
  color: rgb(3, 125, 158);
  font-family: Calibri, "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
  margin-bottom: 4px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
h1 {
  color: rgb(3, 125, 158);
  font-family: Calibri, "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
}

.page {
  margin-left: 6vw;
  margin-right: 6vw;
}

.center {
  justify-content: center;
  display: absolute;
  transform: translate(-50%, -50%);
}

.greyBox {
  width: 15%;
  height: 22%;
  background-color: lightgrey;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 25px;
  margin-left: 2%;
  margin-bottom: 5%;
  float: right;
}

.introText {
  width: 55vw;
  margin-right: 5vw;
  justify-content: left;
  display: inline-block;
}

.map {
  margin-left: 4vw;
  margin-right: 4vw;
}

.headerImage {
  width: 100vw;
  height: 400px;
  margin-bottom: 10px;
}

.roundedGreyBox {
  padding: 15px;
  border-radius: 15px 50px 30px;
  width: 80%;
  margin-right: 10%;
  margin-left: 10%;
  background-color: rgba(128, 128, 128, 0.2);
}

.chartBox {
  width: calc(30% - 20px);
  height: auto;
  border-radius: 25px;
  box-shadow: 0 0 8px 1px grey;
  background-color: rgba(128, 128, 128, 0.2);
  padding-top: 10px;
  margin: 10px;
  float: left;
}
.Charts {
  margin-left: calc(5% + 120px);
}
.MapDetails {
  font-size: 10px;
}
.MapDetailsBox {
  margin-bottom: 55px;
}

.flexbox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.otherResourcesText {
  width: 80%;
}
.SelectionText {
  width: 70%;
  margin-left: 15%;
}
