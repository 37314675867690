.logoText {
  width: 55%;
  height: auto;
  margin-top: 0px;
}
.headerLogoText {
  font-family: Calibri, "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
  text-align: center;
  font-size: 38px;
  color: rgb(5, 56, 92);
  width: 100%;
  height: auto;
  margin-top: 0px;
}

.subLogoText {
  font-family: Calibri, "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
  text-align: center;
  font-size: 28px;
  color: #6699cc;
  width: 100%;
  height: auto;
}
.logo {
  margin-left: 5%;
  width: 20%;
  height: auto;
}

.aligned {
  display: flex;
  align-items: center;
}

.underLine {
  width: 1000px;
  margin-top: 2px;
  margin-left: calc(50% - 500px);
  display: float;
}
