table {
  border-spacing: 1px;
}

tr:nth-child(even) {
  background-color: rgba(3, 125, 158, 0.75);
  overflow: hidden;
  white-space: nowrap;
}
tr:nth-child(odd) {
  background-color: rgba(3, 125, 158, 0.25);
  overflow: hidden;
  white-space: nowrap;
}

.headerRow {
  overflow: hidden;
  white-space: nowrap;
  font-family: Calibri, "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
  font-size: 20px;
}

th {
  background-color: rgb(5, 56, 92);
  color: aliceblue;
  text-align: center;
  line-height: 35px;
}

td {
  padding: 7px;
}

td:nth-child(even) {
  background-color: rgba(3, 125, 158, 0.25);
  overflow: hidden;
  white-space: nowrap;
}
