.cardDiv{
    background-color: transparent;
    border: 0px;
    font-family: Calibri, "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
    font-size: 16px;
    

}
.LineClamped {
    color: black;
}
