.btn {
    background: #49D75C;
    border: 0px solid #49D75C;
    border-radius: 35px;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
    transition: all .25s ease;
    width: 210px;
    position: relative;
    height: 60px;
    overflow: hidden;
}
.btn:not(.loading):hover {
    box-shadow: 0px 10px 25px 0px rgba(73, 215, 92,.4);
}
.btn:not(.loading):hover i {
    transform: translate(7px);
}
.btn i {
    font-size: 1.45rem;
    position: absolute;
    left: 0px;
    pointer-events: none;
    z-index: 10;
    background: inherit;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all .25s ease;
}
.btn .text {
    width: 130px;
    display: block;
    position: relative;
    pointer-events: none;
    transition: all .25s ease;
    position: absolute;
    left: 60px;
}
.loading-animate {
    position: absolute;
    width: 60px;
    height: 60px;
    z-index: 100;
    border-radius: 50%;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    opacity: 0;
    transition: all .25s ease;
}
.loading-animate:after {
    content: '';
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-left: 3px solid #fff;
    animation: loading infinite .8s ease .05s;
    position: absolute;
}
.loading-animate:before {
    content: '';
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-left: 3px solid #fff;
    animation: loading infinite .8s linear;
    position: absolute;
    opacity: .6;
}
.btn.loading {
    width: 60px;
}
.btn.loading i {
    transform: rotate(-90deg);
    padding-bottom: 4px;
    padding-left: 3px;
}
.btn.loading .text {
    transform: translate(-140px);
}
.btn.loading .loading-animate {
    opacity: 1;
}
@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}