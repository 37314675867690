.Searchbar {
  margin-bottom: 10px;
  width: 50vw;
  display: inline-block;
  margin-left: 12px;
}



.sidbarResults {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.ResultsBox {
  width: calc(100vw - 35ch);
}
.Results {
  padding-left: 10px;
  padding-right: 10px;
  display: flex; 
  flex-wrap: wrap;
  margin-bottom: 50px;
  align-self: center;
  width: 100%;
}


.ResultInteriorBox {
  width: calc(25% - 31px);
  text-align: center;
  padding: 6px;
  padding-bottom: 1.2em;
  border-radius: 20px;
  border: 1px solid rgba(3, 125, 158, 0.75);
  background-color: rgba(3, 125, 158, 0.4);
  cursor: pointer;
  margin: 7px;
  transition: 1s;
 
}

.ResultInteriorBox::before, .ResultInteriorBox::after {
  position: absolute;
  background: #eee;
  z-index: -1;
  transition: 1s;
  content: '';
}
.ResultInteriorBox:hover::before {
  width: 0px;
  background: #fff;
}

.ResultInteriorBox:hover::after {
  height: 0px;
  background: #fff;
}

.ResultInteriorBox:hover {
  background: #fff;
}
/* .ResultInteriorBox div:hover {
  box-shadow:0 0 8px 1px green;
  border-radius: 25px;
  padding:1%;
} */

.infoText {
  font-size: 12px;
}

.sideBar {
  padding: 3px;
  left: 10px;
  width: 35ch;
  background-color: rgba(3, 125, 158, 0.25);
  margin-top: 20px;
  margin-bottom: 35px;
  height: 1420px;
}

.CampaignsHeader {
  margin-left: calc(50% - 8ch);
  display: flex;
  flex-direction: row;
  color: purple;
}
.iconHeader {
  padding-top: 3px;
}
.loader {
  margin-top: 20%;
  margin-left: calc(50% - 50px);
}