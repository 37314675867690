[type="file"] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}
[type="file"] + label {
  background-color: rgb(3, 125, 158);
  color: #fff;
  cursor: pointer;
  border-radius: 2rem;
  display: inline-block;
  padding-left: 2rem 4rem;
  padding: 5px;
}
[type="file"]:focus + label,
[type="file"] + label:hover {
  background-color: #0eaad1;
}
[type="file"]:focus + label {
  outline: 1px dotted rgb(14, 11, 236);
}

.spacing {
  margin-top: 15px;
  margin-bottom: 15px;
}
