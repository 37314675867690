.LogoImages {
  float: left;
  padding: 15px;
  margin-left: 15px;
  margin-right: 15px;
}
.LogoSpan {
  display: inline-block;
  width: 100%;
}
