body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

h2 {
  color: rgb(5, 56, 92);
  font-family: Calibri, "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
  margin-bottom: 4px;
}

p {
  font-family: Calibri, "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
  font-size: 18px;
}

h3 {
  color: rgb(3, 125, 158);
  font-family: Calibri, "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
  margin-bottom: 4px;
}
h4 {
  color: rgb(3, 125, 158);
  font-family: Calibri, "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
  margin-bottom: 4px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
h1 {
  color: rgb(3, 125, 158);
  font-family: Calibri, "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
}

.page {
  margin-left: 6vw;
  margin-right: 6vw;
}

.center {
  justify-content: center;
  display: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.greyBox {
  width: 15%;
  height: 22%;
  background-color: lightgrey;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 25px;
  margin-left: 2%;
  margin-bottom: 5%;
  float: right;
}

.introText {
  width: 55vw;
  margin-right: 5vw;
  justify-content: left;
  display: inline-block;
}

.map {
  margin-left: 4vw;
  margin-right: 4vw;
}

.headerImage {
  width: 100vw;
  height: 400px;
  margin-bottom: 10px;
}

.roundedGreyBox {
  padding: 15px;
  border-radius: 15px 50px 30px;
  width: 80%;
  margin-right: 10%;
  margin-left: 10%;
  background-color: rgba(128, 128, 128, 0.2);
}

.chartBox {
  width: calc(30% - 20px);
  height: auto;
  border-radius: 25px;
  box-shadow: 0 0 8px 1px grey;
  background-color: rgba(128, 128, 128, 0.2);
  padding-top: 10px;
  margin: 10px;
  float: left;
}
.Charts {
  margin-left: calc(5% + 120px);
}
.MapDetails {
  font-size: 10px;
}
.MapDetailsBox {
  margin-bottom: 55px;
}

.flexbox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.otherResourcesText {
  width: 80%;
}
.SelectionText {
  width: 70%;
  margin-left: 15%;
}

/*style the box*/
.gm-style .gm-style-iw {
  background-color: #3c61ad !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  min-height: 180px;
  padding-top: 10px;
}

/*style the p tag*/
.gm-style .gm-style-iw #google-popup p {
  padding: 10px;
}

/*style the arrow*/
.gm-style div div div div div div div div {
  background-color: #3c61ad !important;
  padding: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  top: 0;
  color: #fff;
  font-size: 16px;
}

/*style the link*/
.gm-style div div div div div div div div a {
  color: #f1f1f1;
  font-weight: bold;
}

.MapMarker {
  color: #fff;
}

.LogoImages {
  float: left;
  padding: 15px;
  margin-left: 15px;
  margin-right: 15px;
}
.LogoSpan {
  display: inline-block;
  width: 100%;
}

.panelBoxProgramComparison {
  margin-left: 12%;
  margin-right: 12%;
}
.panelBoxAddProgram {
  margin-left: 15%;
  margin-right: 15%;
}

.rightbox {
  display: inline;
  float: left;
  margin-left: 10%;
}

.leftbox {
  display: inline;
  float: left;
  margin-left: 10%;
}

.programComparisonTable {
  width: 100%;
}

.buffer {
  margin-top: 10px;
  margin-bottom: 60px;
}

[type="file"] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}
[type="file"] + label {
  background-color: rgb(3, 125, 158);
  color: #fff;
  cursor: pointer;
  border-radius: 2rem;
  display: inline-block;
  padding-left: 2rem 4rem;
  padding: 5px;
}
[type="file"]:focus + label,
[type="file"] + label:hover {
  background-color: #0eaad1;
}
[type="file"]:focus + label {
  outline: 1px dotted rgb(14, 11, 236);
}

.spacing {
  margin-top: 15px;
  margin-bottom: 15px;
}

table {
  border-spacing: 1px;
}

tr:nth-child(even) {
  background-color: rgba(3, 125, 158, 0.75);
  overflow: hidden;
  white-space: nowrap;
}
tr:nth-child(odd) {
  background-color: rgba(3, 125, 158, 0.25);
  overflow: hidden;
  white-space: nowrap;
}

.headerRow {
  overflow: hidden;
  white-space: nowrap;
  font-family: Calibri, "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
  font-size: 20px;
}

th {
  background-color: rgb(5, 56, 92);
  color: aliceblue;
  text-align: center;
  line-height: 35px;
}

td {
  padding: 7px;
}

td:nth-child(even) {
  background-color: rgba(3, 125, 158, 0.25);
  overflow: hidden;
  white-space: nowrap;
}

.leftBox {
  position: relative;
  width: calc(50% - 25px);
  margin-left: 10px;
  margin-right: 15px;
  height: 0px;
}

@media (max-width: 1400px) {
  .leftBox {
    height: auto;
    width: calc(50% - 25px);
    margin-left: 10px;
    margin-right: 15px;
  }
  .rightBox {
    margin-top: 20px;
    float: left;
  }
}

.rightBox {
  position: relative;
  margin-left: 15px;
  margin-right: 10px;
  left: calc(50% + 50px);
  width: calc(50% - 25px);
}

.ButtonDivSubmit {
  margin-top: 40px;
  margin-left: calc(50% - 38px);
}

.buttonNext {
  float: right;
  margin-right: 25px;
  padding-top: 10px;
  padding-bottom: 15px;
}
.buttonNextDataCollection {
  float: right;
  margin-right: 25px;
  padding-top: 2px;
  padding-bottom: 15px;
}
.buttonPrevious {
  float: left;
  margin-left: 25px;
  padding-top: 10px;
  padding-bottom: 15px;
}

.btn {
    background: #49D75C;
    border: 0px solid #49D75C;
    border-radius: 35px;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
    transition: all .25s ease;
    width: 210px;
    position: relative;
    height: 60px;
    overflow: hidden;
}
.btn:not(.loading):hover {
    box-shadow: 0px 10px 25px 0px rgba(73, 215, 92,.4);
}
.btn:not(.loading):hover i {
    -webkit-transform: translate(7px);
            transform: translate(7px);
}
.btn i {
    font-size: 1.45rem;
    position: absolute;
    left: 0px;
    pointer-events: none;
    z-index: 10;
    background: inherit;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all .25s ease;
}
.btn .text {
    width: 130px;
    display: block;
    position: relative;
    pointer-events: none;
    transition: all .25s ease;
    position: absolute;
    left: 60px;
}
.loading-animate {
    position: absolute;
    width: 60px;
    height: 60px;
    z-index: 100;
    border-radius: 50%;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    opacity: 0;
    transition: all .25s ease;
}
.loading-animate:after {
    content: '';
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-left: 3px solid #fff;
    -webkit-animation: loading infinite .8s ease .05s;
            animation: loading infinite .8s ease .05s;
    position: absolute;
}
.loading-animate:before {
    content: '';
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-left: 3px solid #fff;
    -webkit-animation: loading infinite .8s linear;
            animation: loading infinite .8s linear;
    position: absolute;
    opacity: .6;
}
.btn.loading {
    width: 60px;
}
.btn.loading i {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    padding-bottom: 4px;
    padding-left: 3px;
}
.btn.loading .text {
    -webkit-transform: translate(-140px);
            transform: translate(-140px);
}
.btn.loading .loading-animate {
    opacity: 1;
}
@-webkit-keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.Searchbar {
  margin-bottom: 10px;
  width: 50vw;
  display: inline-block;
  margin-left: 12px;
}



.sidbarResults {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.ResultsBox {
  width: calc(100vw - 35ch);
}
.Results {
  padding-left: 10px;
  padding-right: 10px;
  display: flex; 
  flex-wrap: wrap;
  margin-bottom: 50px;
  align-self: center;
  width: 100%;
}


.ResultInteriorBox {
  width: calc(25% - 31px);
  text-align: center;
  padding: 6px;
  padding-bottom: 1.2em;
  border-radius: 20px;
  border: 1px solid rgba(3, 125, 158, 0.75);
  background-color: rgba(3, 125, 158, 0.4);
  cursor: pointer;
  margin: 7px;
  transition: 1s;
 
}

.ResultInteriorBox::before, .ResultInteriorBox::after {
  position: absolute;
  background: #eee;
  z-index: -1;
  transition: 1s;
  content: '';
}
.ResultInteriorBox:hover::before {
  width: 0px;
  background: #fff;
}

.ResultInteriorBox:hover::after {
  height: 0px;
  background: #fff;
}

.ResultInteriorBox:hover {
  background: #fff;
}
/* .ResultInteriorBox div:hover {
  box-shadow:0 0 8px 1px green;
  border-radius: 25px;
  padding:1%;
} */

.infoText {
  font-size: 12px;
}

.sideBar {
  padding: 3px;
  left: 10px;
  width: 35ch;
  background-color: rgba(3, 125, 158, 0.25);
  margin-top: 20px;
  margin-bottom: 35px;
  height: 1420px;
}

.CampaignsHeader {
  margin-left: calc(50% - 8ch);
  display: flex;
  flex-direction: row;
  color: purple;
}
.iconHeader {
  padding-top: 3px;
}
.loader {
  margin-top: 20%;
  margin-left: calc(50% - 50px);
}
.cardDiv{
    background-color: transparent;
    border: 0px;
    font-family: Calibri, "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
    font-size: 16px;
    

}
.LineClamped {
    color: black;
}

.questionLabels {
  font-family: Calibri, "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
  font-size: 18px;
  line-height: 35px;
}

.buttonSubmit {
  margin-top: 35px;
  margin-left: calc(50% - 50px);
}

.logoText {
  width: 55%;
  height: auto;
  margin-top: 0px;
}
.headerLogoText {
  font-family: Calibri, "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
  text-align: center;
  font-size: 38px;
  color: rgb(5, 56, 92);
  width: 100%;
  height: auto;
  margin-top: 0px;
}

.subLogoText {
  font-family: Calibri, "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
  text-align: center;
  font-size: 28px;
  color: #6699cc;
  width: 100%;
  height: auto;
}
.logo {
  margin-left: 5%;
  width: 20%;
  height: auto;
}

.aligned {
  display: flex;
  align-items: center;
}

.underLine {
  width: 1000px;
  margin-top: 2px;
  margin-left: calc(50% - 500px);
  display: float;
}

.campaignBox {
  margin-top: 100px;
  padding: 20px;
  border-radius: 25px;
  width: 40%;
  background-color: rgba(128, 128, 128, 0.2);
  box-shadow: 0 0 8px 1px grey;
  min-height: 50vh;
  float: left;
  margin-left: 75px;
  margin-bottom: 50px;
}
.menuContainer {
  float: left;
  margin-left: calc(20% - 75px);
  width: 20%;
  height: auto;
  flex-wrap: wrap;
  margin-bottom: 50px;
}
.menu {
  margin: auto;
}

.headerBox {
  display: flex;
}

.mapBox {
  margin-bottom: 20px;
  background-color: rgba(128, 128, 128, 0.2);
  box-shadow: 0 0 8px 1px grey;
  height: auto;
  width: 93%;
  padding: 10px;
  display: flex;
  margin-top: 20px;
}

.no-bullets {
  list-style-type: none; /* Remove bullets */
  margin-left: 8px;
  padding: 8px;
}
.headerParagraphs {
  max-width: 33.3%;
}
.boldHighlight {
  font-size: 12pt;
}

